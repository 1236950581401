import axios from "axios"
import { PROPS_, utcParseDate, YYYYMMDD } from "basikon-common-utils"
import get from "lodash.get"
import set from "lodash/set"
import React, { createRef } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"

import { getEntitySearchFieldNames, getEntitySearchFields, toFieldNames } from "@/_services/entity"
import { getList } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { userPreferencesTypes } from "@/_services/theming"
import { getOptions, getUsername, getUserPreferences } from "@/_services/userConfiguration"
import { labelFromName, mergeQueryParams, reservedQueryParams, searchParamToObject } from "@/_services/utils"

/**
 * @prop {[{}]}     advancedSearch.fields             Array of fields to override standard entity search fields
 * @prop {[{}]}     advancedSearch.additionalFields   Array of additional fields to be added after the standard entity search fields
 * @prop {{}}       advancedSearch.props_{field}      Object to apply field props on {field}
 */

class AdvancedSearch extends React.Component {
  constructor(props) {
    super(props)

    const { advancedSearch, location, advancedSearchInModal, sortableFields } = props
    const sortableFieldsSelect = Array.isArray(sortableFields)
      ? sortableFields
          .filter(field => {
            if (!field) return

            let fieldName = field
            let isFieldSortable = true
            if (typeof field === "object") {
              const { name, sortable = true } = field
              fieldName = name
              isFieldSortable = sortable
            }

            // hidden fields must not be filtered
            // we want to be able to sort on them without showing them
            return fieldName && fieldName !== "actions" && isFieldSortable
          })
          .map(({ name, title }) => {
            return {
              value: name,
              label: loc(title || labelFromName(name)),
            }
          })
      : []
    const hasSortableFields = sortableFieldsSelect.length

    this.state = {
      searchForm: {},
      searchFields: [],
      fieldRows: [],
      selectedFields: {},
      savedSearchFilters: [],
      preferenceGroupId: advancedSearch?.groupId || location.pathname,
      username: getUsername(),
      savedSearchFiltersComponent: null,
      inModal: advancedSearchInModal,
      hasSortableFields,
      sortableFieldsSelect,
    }

    this.isCtrlKeyPressed = createRef()
  }

  async componentDidMount() {
    const { preferenceGroupId, inModal } = this.state
    const { location, advancedSearch, entityName, fields } = this.props

    const _fields = fields || advancedSearch?.fields
    const _entityName = entityName || advancedSearch?.entity

    const searchFields = getSearchFields({ entityName: _entityName, fields: _fields, advancedSearch })
    const queryParams = searchParamToObject(location.search)
    const searchForm = { ...(advancedSearch?.form || {}) }
    for (const field in queryParams) {
      if (reservedQueryParams.includes(field)) continue

      const fieldProps = searchFields.flat().find(f => f.field === field)
      const fieldRegex = fieldProps?.regex

      let value = queryParams[field]
      if (fieldRegex && value.startsWith("/") && value.endsWith("/i")) {
        let regexValue = value.substring(1, value.length - 2)
        if (regexValue.startsWith("^")) regexValue = regexValue.substring(1)
        set(searchForm, field, regexValue)
      } else {
        // Parse dates: "20230222" => new Date(2023, 02, 22)
        if (fieldProps?.type === "date" && value && !(value instanceof Date)) {
          value = utcParseDate(value, getLocale())
        }

        const isMultipleField = fieldProps?.multiple || fieldProps?.type === "multiple"
        if (isMultipleField) {
          set(searchForm, field, (value ? decodeURIComponent(value) : "").split(","))
        } else if (fieldProps?.decodeUri) {
          set(searchForm, field, decodeURIComponent(value))
        } else {
          set(searchForm, field, value)
        }
      }
    }

    // Apply fields props
    for (const key of Object.keys(advancedSearch || {}).filter(key => key?.startsWith("props_"))) {
      searchForm[key] = { ...(searchForm[key] || {}), ...(advancedSearch[key] || {}) }
    }

    if (queryParams.sort) {
      set(searchForm, "sort", queryParams.sort.split(","))
    }

    const { fieldRows, fieldsBadges: selectedFields } = this.getFieldsRowsAndBadges({
      displayMode: "badge",
      searchFields,
      sourceObject: searchForm,
      handleClear: true,
    })

    const statePatch = { searchForm, searchFields, fieldRows, selectedFields }

    if (!inModal) {
      const userPreferences = getUserPreferences()
      statePatch.savedSearchFilters = userPreferences.filter(({ type, groupId }) => {
        return type === userPreferencesTypes.SEARCH_FILTER && groupId === preferenceGroupId
      })
    }

    statePatch.inModal = this.getInModalFromUserPreferences()
    this.setState(statePatch, this.setSavedSearchFiltersComponent)

    document.addEventListener("keydown", this.handleKeyboardShortcuts)
    document.addEventListener("keyup", this.handleKeyboardCtrlKey)
  }

  getInModalFromUserPreferences = () => {
    const { advancedSearch, location } = this.props
    const advancedSearchUserConf = getOptions("advancedSearch")

    const userPreferences = getUserPreferences()
    const preferenceGroupId = location.pathname
    const userPreference =
      userPreferences.find(({ type, groupId }) => {
        return type === userPreferencesTypes.TABLE_CONFIGURATION && groupId === preferenceGroupId
      }) || {}

    return userPreference?.value?.advancedSearchInModal ?? advancedSearch?.inModal ?? advancedSearchUserConf?.inModal ?? true
  }

  componentDidUpdate = prevProps => {
    const { inModal: prevStateInModal } = this.state
    const { show } = this.props
    if (!prevProps?.show && show) {
      const inModal = this.getInModalFromUserPreferences()
      if (prevStateInModal !== inModal) {
        this.setState({ inModal })
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyboardShortcuts)
    document.removeEventListener("keyup", this.handleKeyboardCtrlKey)
  }

  handleKeyboardCtrlKey = event => {
    event.stopPropagation()
    const { show } = this.props
    if (!show) return

    const { ctrlKey } = event
    this.isCtrlKeyPressed.current = ctrlKey
  }

  handleKeyboardShortcuts = event => {
    event.stopPropagation()
    const { show } = this.props
    if (!show) return

    const { keyCode, metaKey, ctrlKey, altKey: ALT } = event
    const isMac = window.navigator.platform.match("Mac")
    const CTRL = isMac ? metaKey : ctrlKey
    const S = keyCode === 83
    this.isCtrlKeyPressed.current = ctrlKey

    if (ALT && S) this.launchSearch()
    if (CTRL && S) this.saveSearchFilters()
  }

  getList = (list, options) => {
    const result = getList(list, result => this.setState({ [list]: result.values }), options)
    this.setState({ [list]: result.values })
  }

  setSearchFilters = ({ patch, select }) => {
    const { searchForm = {} } = this.state
    const patchKey = Object.keys(patch)[0]
    const patchValue = patch[patchKey]

    // Only multi select is supported as this is the only relevant type of field for this feature
    if (select && Array.isArray(patchValue) && this.isCtrlKeyPressed.current) {
      if (searchForm[patchKey]?.length > 0) {
        const diffValue = patchValue.filter(element => !searchForm[patchKey].includes(element))[0]
        // this prevents adding the same negative filter again
        if (patchValue.find(element => element === `-${diffValue}`)) return
        for (let i = 0; i < patchValue.length; i++) {
          const selectValue = patchValue[i]
          if (selectValue === diffValue) {
            patchValue[i] = `-${diffValue}`
            break
          }
        }
      } else {
        patch[patchKey] = [`-${patchValue[0]}`]
      }
    }

    if (patchValue === "false" || patchValue === false) patch[patchKey] = undefined
    if (patchValue === "true") patch[patchKey] = true

    const isEmptyPatchValue = !patchValue || patchValue.length === 0
    if (isEmptyPatchValue) {
      delete searchForm[patchKey]
      delete searchForm[`${PROPS_}${patchKey}`]
      this.setState({ searchForm }, this.refreshFieldsRowsAndBadges)
    } else {
      this.setState({ searchForm: { ...searchForm, ...patch } }, this.refreshFieldsRowsAndBadges)
    }
  }

  launchSearch = async () => {
    const { searchForm = {}, inModal: stateInModal } = this.state
    const { close, location, history, searchFunction, entityName, advancedSearch, advancedSearchInModal, fields, encodeValues = true } = this.props
    const inModal = advancedSearchInModal ?? stateInModal

    const _fields = fields || advancedSearch?.fields
    const _entityName = entityName || advancedSearch?.entity

    if (!searchFunction && !_entityName) return

    // Reset quick search and filter when searching
    searchForm.search = null
    searchForm.filter = null

    let params = {}
    if (encodeValues) {
      const searchFields = getSearchFields({ entityName: _entityName, fields: _fields, advancedSearch })
      const fieldNames = typeof searchFields === "string" ? getEntitySearchFieldNames(searchFields) : toFieldNames(searchFields)

      for (const fieldName of fieldNames) {
        const fieldRegex = searchFields.flat().find(f => f.field === fieldName)?.regex
        const value = get(searchForm, fieldName)

        if (fieldRegex && value) {
          if (typeof fieldRegex === "object") {
            const { fromStart, honorCase } = fieldRegex
            params[fieldName] = `/${fromStart ? "^" : ""}${value}${honorCase ? "" : "/i"}`
          } else {
            params[fieldName] = `/${value}/i`
          }
        } else if (value instanceof Date) {
          params[fieldName] = YYYYMMDD(value)
        } else if (value) {
          params[fieldName] = encodeURIComponent(value)
        } else if (value === "" || value === null || value === undefined) {
          params[fieldName] = null
        }
      }
    } else {
      params = searchForm
    }

    if (searchForm.sort?.length) {
      params.sort = searchForm.sort.join(",")
    } else {
      // this is needed so that the query params is removed
      params.sort = null
    }

    if (searchFunction) {
      await searchFunction({ params, isAdvancedSearch: true })
    } else {
      const queryParams = mergeQueryParams(location.search, params)
      history.replace(`${location.pathname}${queryParams}`)
    }

    if (inModal && close) close()
  }

  saveSearchFilters = async () => {
    const { inModal } = this.props
    const { savedSearchFilters, searchForm, username, preferenceGroupId, searchFields } = this.state
    if (inModal || !Object.keys(searchForm).length) return

    // This prevents saving filters set in the URL
    // but not in the available search fields.
    // This happens when opening entity pages with links having filters already set
    // or when opening these pages manually while writing filters in the URL.
    const { fieldsBadges } = this.getFieldsRowsAndBadges({ searchFields, sourceObject: searchForm })

    for (const formKey in searchForm) {
      if (formKey.startsWith(PROPS_) || formKey === "range" || !fieldsBadges[formKey]) {
        delete searchForm[formKey]
      }
    }
    if (Object.keys(searchForm).length === 0) return

    const stringifiedSearchForm = JSON.stringify(searchForm)
    for (let i = 0; i < savedSearchFilters.length; i++) {
      if (JSON.stringify(savedSearchFilters[i].value) === stringifiedSearchForm) return
    }

    const { data: savedSearchFilter } = await axios.post(`/api/core/users/${username}/preferences`, {
      groupId: preferenceGroupId,
      type: userPreferencesTypes.SEARCH_FILTER,
      value: searchForm,
    })
    savedSearchFilters.push(savedSearchFilter)
    this.setState({ savedSearchFilters }, this.setSavedSearchFiltersComponent)
  }

  deleteSavedSearchFilters = async ({ event, index }) => {
    event.stopPropagation()
    const { savedSearchFilters, username } = this.state
    const savedSearchFilter = savedSearchFilters.splice(index, 1)[0]
    await axios.delete(`/api/core/users/${username}/preferences/${savedSearchFilter._id}`)
    this.setState({ savedSearchFilters }, this.setSavedSearchFiltersComponent)
  }

  recallSavedSearchFilters = ({ event, index }) => {
    event.stopPropagation()
    const { savedSearchFilters } = this.state
    const searchForm = JSON.parse(JSON.stringify(savedSearchFilters[index].value))
    this.setState({ searchForm }, () => {
      this.refreshFieldsRowsAndBadges()
      this.launchSearch()
    })
  }

  reset = () => {
    const { inModal } = this.state
    const { close, searchFunction, history, location, entityName, advancedSearch, fields } = this.props

    const _fields = fields || advancedSearch?.fields
    const _entityName = entityName || advancedSearch?.entity
    const searchFields = getSearchFields({ entityName: _entityName, advancedSearch, fields: _fields })

    const resetParams = { page: 1, sort: null }
    searchFields.flat().forEach(field => (resetParams[field.field] = undefined))

    const searchForm = Object.keys(advancedSearch || {})
      .filter(key => key?.startsWith("props_"))
      .reduce((obj, key) => {
        obj[key] = advancedSearch[key]
        return obj
      }, {})

    searchForm.sort = advancedSearch?.sort || []

    this.setState({ searchForm }, this.refreshFieldsRowsAndBadges)

    if (searchFunction) {
      searchFunction({ params: resetParams, isAdvancedSearch: true, isReset: true })
    } else {
      const queryParams = mergeQueryParams(location.search, resetParams)
      history.replace(`${location.pathname}${queryParams}`)
    }
    if (inModal && close && advancedSearch?.closeModalOnReset !== false) close()
  }

  refreshFieldsRowsAndBadges = () => {
    const { searchForm = {}, searchFields } = this.state
    const { fieldRows, fieldsBadges: selectedFields } = this.getFieldsRowsAndBadges({
      displayMode: "badge",
      searchFields,
      sourceObject: searchForm,
      handleClear: true,
    })
    this.setState({ fieldRows, selectedFields })
  }

  getFieldsRowsAndBadges = ({ searchFields, sourceObject, displayMode, handleClear }) => {
    const fieldsBadges = {}
    const fieldRows = searchFields.map((fieldsArray, rowIndex) => {
      const fieldCols = (fieldsArray || []).map(
        ({
          col,
          colProps,
          legend,
          field,
          type,
          label,
          select,
          placeholder,
          searchEntityName,
          query,
          hidden,
          actionHidden,
          defaultOptions,
          multiple,
          creatable,
          currency,
          timeFormat,
          columns,
          advancedSearch,
          debounce,
        }) => {
          colProps = colProps || col || { xs: 12, md: 6, lg: 4 } // TODO: Remove "col" prop when all scripts are migrated

          if (hidden === true) return null
          if (legend) {
            return (
              <Col key={rowIndex + field} xs={12}>
                <legend className="font-2 mt-2">{loc(legend)}</legend>
              </Col>
            )
          }
          if (!label) label = labelFromName(field)

          const handleClearFunction = handleClear ? () => this.setSearchFilters({ patch: { [field]: null } }) : null
          let value = get(sourceObject, field)
          const isMultipleField = type === "multiple" || multiple

          if (searchEntityName) {
            if (value) {
              fieldsBadges[field] = (
                <FormInputExtended
                  displayMode={displayMode}
                  readOnly
                  obj={sourceObject}
                  key={field}
                  query={query}
                  field={field}
                  label={label}
                  multiple={isMultipleField}
                  colProps={colProps}
                  placeholder={placeholder}
                  debounce={debounce}
                  actionHidden={actionHidden}
                  columns={columns}
                  advancedSearch={advancedSearch}
                  defaultOptions={defaultOptions}
                  searchEntityName={searchEntityName}
                  handleClear={handleClearFunction}
                />
              )
            }
            return (
              <FormInputExtended
                obj={sourceObject}
                key={field}
                query={query}
                field={field}
                label={label}
                colProps={colProps}
                placeholder={placeholder}
                debounce={debounce}
                actionHidden={actionHidden}
                columns={columns}
                multiple={isMultipleField}
                advancedSearch={advancedSearch}
                defaultOptions={defaultOptions}
                searchEntityName={searchEntityName}
                onSetState={patch => this.setSearchFilters({ patch, select })}
              />
            )
          } else if (isMultipleField) {
            if (value) {
              fieldsBadges[field] = (
                <FormInputExtended
                  readOnly
                  multiple
                  key={field}
                  field={field}
                  label={label}
                  select={select}
                  obj={sourceObject}
                  colProps={colProps}
                  handleNegativeValues
                  creatable={creatable}
                  displayMode={displayMode}
                  placeholder={placeholder}
                  debounce={debounce}
                  handleClear={handleClearFunction}
                />
              )
            }
            return (
              <FormInputExtended
                multiple
                key={field}
                field={field}
                label={label}
                select={select}
                obj={sourceObject}
                colProps={colProps}
                handleNegativeValues
                creatable={creatable}
                placeholder={placeholder}
                debounce={debounce}
                onSetState={patch => this.setSearchFilters({ patch, select })}
              />
            )
          } else {
            if (value) {
              // we have to do this conversion because the date value stored in searchForm
              // is YYYYMMDD due to the need to have it in the URL
              if (type === "date") value = utcParseDate(value, getLocale())
              fieldsBadges[field] = (
                <FormInput
                  readOnly
                  key={field}
                  type={type}
                  value={value}
                  field={field}
                  label={label}
                  select={select}
                  currency={currency}
                  colProps={colProps}
                  creatable={creatable}
                  timeFormat={timeFormat}
                  displayMode={displayMode}
                  placeholder={placeholder}
                  debounce={debounce}
                  handleClear={handleClearFunction}
                />
              )
            }

            return (
              <FormInput
                key={field}
                type={type}
                field={field}
                label={label}
                select={select}
                obj={sourceObject}
                colProps={colProps}
                currency={currency}
                creatable={creatable}
                timeFormat={timeFormat}
                placeholder={placeholder}
                debounce={debounce}
                onEnter={this.launchSearch}
                onSetState={patch => this.setSearchFilters({ patch, select })}
              />
            )
          }
        },
      )

      return <Row key={rowIndex}>{fieldCols}</Row>
    })

    if (sourceObject.sort?.length) {
      fieldsBadges.sort = (
        <FormInputExtended
          readOnly
          multiple
          key="sort"
          field="sort"
          label={
            <>
              <i className="icn-sort icn-xxs" />
              {loc("Sorting data")}
            </>
          }
          select={this.state.sortableFieldsSelect}
          obj={sourceObject}
          handleNegativeValues
          displayMode={displayMode}
          handleClear={handleClear ? () => this.setDataSort({ sort: [] }) : null}
        />
      )
    }

    return { fieldRows, fieldsBadges }
  }

  setSavedSearchFiltersComponent = () => {
    const { searchFields, savedSearchFilters } = this.state
    const savedSearchFiltersComponent = (
      <div className="mt-5px">
        <div className="mb-5px font-weight-bold">{loc("Saved searches")}</div>

        {savedSearchFilters.map((savedSearchFilter, index) => {
          const { fieldsBadges } = this.getFieldsRowsAndBadges({ displayMode: "raw", searchFields, sourceObject: savedSearchFilter?.value })
          const savedSearchFilterKeys = Object.keys(savedSearchFilter?.value).filter(key => !key.startsWith(PROPS_) && fieldsBadges[key])
          const nbOfSavedSearchFilterKeys = savedSearchFilterKeys.length - 1
          return (
            <div
              onClick={event => this.recallSavedSearchFilters({ event, index })}
              key={index}
              className="bg-gray-lightest bg-hover-gray-lighter inline-flex-center border-1px br-theme border-solid border-gray font-1-2 leading-2 mr-5px mb-5px pdr-5px inline-flex-center c-pointer"
            >
              <ButtonWithTooltip
                pullRight
                bsStyle="default"
                bsSize="xs"
                aria-label={loc("Deleted saved search")}
                tooltip={"Deleted saved search"}
                onClick={event => this.deleteSavedSearchFilters({ event, index })}
                btnClassName="m-0"
                className="icn-xmark icn-xxs flex-center text-danger"
              />
              {savedSearchFilterKeys.map((selectedField, jndex) => {
                return (
                  <span key={jndex}>
                    {fieldsBadges[selectedField]}
                    {jndex < nbOfSavedSearchFilterKeys ? <>,&nbsp;</> : ""}
                  </span>
                )
              })}
            </div>
          )
        })}
      </div>
    )

    this.setState({ savedSearchFiltersComponent })
  }

  setDataSort = ({ sort }) => {
    const { searchForm } = this.state
    this.setState({ searchForm: { ...searchForm, sort } }, this.refreshFieldsRowsAndBadges)
  }

  render() {
    const { bsSize = "lg", close, loading, show, advancedSearchInModal } = this.props
    const {
      fieldRows,
      selectedFields,
      savedSearchFilters = [],
      savedSearchFiltersComponent,
      inModal: stateInModal,
      hasSortableFields,
      sortableFieldsSelect,
      searchForm,
    } = this.state
    const inModal = advancedSearchInModal ?? stateInModal
    const isEmptySearch = Object.keys(selectedFields).length === 0 && !searchForm.sort?.length
    const hasSavedSearches = savedSearchFilters.length > 0

    const controlButtons = (
      <div>
        <CustomButton bsSize="sm" onClick={close}>{loc`Close`}</CustomButton>
        <CustomButton bsStyle="warning" bsSize="sm" fill onClick={this.reset} disabled={isEmptySearch}>
          {loc`Reset`}
        </CustomButton>
        <ButtonWithTooltip
          bsStyle="info"
          bsSize="sm"
          fill
          onClick={this.launchSearch}
          btnClassName="inline-flex-center"
          disabled={loading}
          tooltip="ALT + S"
          ariaLabel="Search"
        >
          {loc`Search`}
          {loading && <i className="icn-circle-notch icn-spin icn-xs text-gray-darker mr-5px" />}
        </ButtonWithTooltip>
      </div>
    )

    let sortableFieldsSection = null
    if (hasSortableFields) {
      sortableFieldsSection = (
        <>
          <div className="pdt-theme bt-dashed border-1px border-gray"></div>
          <Row>
            <FormInputExtended
              label="Sorting data"
              obj={searchForm}
              field="sort"
              select={sortableFieldsSelect}
              handleNegativeValues
              multiple
              onSetState={this.setDataSort}
              colProps={{ xs: 12, lg: inModal ? 12 : 6 }}
            />
          </Row>
        </>
      )
    }

    const advancedSearchComponent = (
      <div className={inModal ? "" : "advanced-search card no-card"} data-show={show}>
        {!inModal && <div className="title mt-theme mb-theme pdt-theme bt-solid border-1px border-gray">{loc`Advanced search`}</div>}

        <div>
          {fieldRows}
          {sortableFieldsSection}
        </div>

        {!inModal && (
          <div className="mb-theme pdb-theme bb-solid border-1px border-gray">
            {controlButtons}

            {!isEmptySearch && (
              <div className="mt-5px">
                <div className="flex align-items-center flex-wrap">
                  <ButtonWithTooltip
                    btnClassName="m-0 mr-5px mt-5px"
                    bsSize="xs"
                    bsStyle="default"
                    fill
                    simple={false}
                    tooltip={"Save (CTRL + S)"}
                    onClick={this.saveSearchFilters}
                  >
                    <i className="icn-save-light icn-xs mr-5px" /> {loc("Save search")}
                  </ButtonWithTooltip>
                  {Object.keys(selectedFields).map(selectedField => selectedFields[selectedField])}
                </div>
              </div>
            )}

            {hasSavedSearches && savedSearchFiltersComponent}
          </div>
        )}
      </div>
    )

    return inModal ? (
      <Modal show={show} onHide={close} bsSize={bsSize} backdrop="static" className="advanced-search-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Advanced search`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{advancedSearchComponent}</Modal.Body>
        <Modal.Footer>{controlButtons}</Modal.Footer>
      </Modal>
    ) : (
      advancedSearchComponent
    )
  }
}

export default withRouter(AdvancedSearch)

export function countSearchParams(params = {}, entityNameOrFields) {
  const fieldNames = typeof entityNameOrFields === "string" ? getEntitySearchFieldNames(entityNameOrFields) : toFieldNames(entityNameOrFields)

  let count = 0
  for (const fieldName of fieldNames) {
    const value = get(params, fieldName)
    if (value !== null && value !== undefined) count++
  }
  return count
}

export function getSearchFields({ entityName, fields = [], advancedSearch }) {
  const entitySearchFields = getEntitySearchFields(entityName)

  let searchFields
  if (advancedSearch && Array.isArray(advancedSearch.fields) && advancedSearch.fields.length && entitySearchFields) {
    const entitySearchFieldsObj = entitySearchFields.flat().reduce((obj, searchField) => {
      if (searchField.field) obj[searchField.field] = searchField
      return obj
    }, {})

    // Apply "entitySearchFields" props on configuration "fields" to reduce configuration
    searchFields = advancedSearch.fields.map(row =>
      row.map(field => {
        if (!field?.field || typeof field?.field !== "string") return field

        const entitySearchField = entitySearchFieldsObj[field.field]
        return entitySearchField ? { ...entitySearchField, ...field } : field
      }),
    )
  } else if (Array.isArray(fields) && fields.length) searchFields = fields
  else if (entityName) searchFields = entitySearchFields

  if (advancedSearch && Array.isArray(advancedSearch.additionalFields)) return [...(searchFields || []), ...advancedSearch.additionalFields]

  return searchFields || []
}
