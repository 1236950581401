import React, { Suspense } from "react"
import { Col, Modal, Row } from "react-bootstrap"
import { Link, withRouter } from "react-router-dom"

import AppLoadingAnimation from "@/_components/AppLoadingAnimation"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import DebugPopover from "@/_components/DebugPopover"
import EntityModelModal from "@/_components/EntityModelModal"
import { cardNames } from "@/_components/LayoutCard"
import LazySkeletonUi from "@/_components/LazySkeletonUi"
import PanelOuter from "@/_components/PanelOuter"

import { loc } from "@/_services/localization"
import { getUiState, setUiState } from "@/_services/uiState"
import { getConfigAtPath } from "@/_services/userConfiguration"
import {
  applyClasses,
  cardsCollapseToggleId,
  customEvents,
  debug,
  downloadFileFromUrl,
  handleAccessibleOnKeyDown,
  localStorageKeys,
  sideColumnToggleId,
} from "@/_services/utils"
import { getFieldProps, labelToName } from "basikon-common-utils"

class Card extends React.Component {
  constructor(props) {
    super(props)

    const { collapse, setRef } = props
    this.state = {
      expanded: collapse !== undefined ? !collapse : true,
      toggleExpanded: true,
      areCardsToggled: false,
    }

    this.cardRef = React.createRef()
    this.cardCallbackRef = React.createRef()
    if (typeof setRef === "function") setRef(this.cardRef)
  }

  componentDidMount() {
    this.cardRef.current.addEventListener(customEvents.card.scrollToModelField, this.scrollToModelField, true)
    window.addEventListener(customEvents.card.openTabCallback, this.scrollToModelField, true)
    window.addEventListener(customEvents.card.toggle, this.eventToggle, true)
  }

  componentWillUnmount() {
    this.cardRef.current.removeEventListener(customEvents.card.scrollToModelField, this.scrollToModelField, true)
    window.removeEventListener(customEvents.card.openTabCallback, this.scrollToModelField, true)
    window.removeEventListener(customEvents.card.toggle, this.eventToggle, true)
  }

  componentDidUpdate(prevProps) {
    const { collapse } = this.props
    if (prevProps.collapse !== collapse) {
      this.setState({
        expanded: collapse !== undefined ? !collapse : true,
      })
    }
  }

  eventToggle = () => {
    const { toggleExpanded } = this.state
    const { collapse } = this.props
    const isCollapsible = collapse !== undefined && collapse !== null
    if (isCollapsible) {
      this.setState({ expanded: !toggleExpanded, toggleExpanded: !toggleExpanded })
    }
  }

  focusOnElement = (modelFieldElement, options) => {
    const formGroupCssErrorClass = options?.errorClass || "form-group-error-focus"
    modelFieldElement.scrollIntoView({ behavior: "smooth", block: "center" })
    modelFieldElement.classList.add(formGroupCssErrorClass)
    setTimeout(() => modelFieldElement.classList.remove(formGroupCssErrorClass), 2000)
  }

  getModelFieldPathAttribute = modelFieldPath => `[data-model-field-path="${modelFieldPath}"]`

  getDocumentModelFieldPathElement = modelFieldPath => {
    return document.querySelector(this.getModelFieldPathAttribute(modelFieldPath))
  }

  getInnerPanelModelFieldPathElement = modelFieldPath => {
    return this.cardRef.current.querySelector(`.panel-inner ${this.getModelFieldPathAttribute(modelFieldPath)}`)
  }

  getModelFieldPathElement = modelFieldPath => {
    return this.cardRef.current.querySelector(this.getModelFieldPathAttribute(modelFieldPath))
  }

  /**
   * If the modelFieldElement is found in the child DOM of this card (static card content) then expand the card if needed and focus on the element
   * Otherwise :
   * - If the modelFieldElement is found somewhere in the DOM, then it is handled by another Card component instance, in this instance we don't anything to do
   * - If this card has the props onSetExpanded (dynamic card content, loaded when the card is expanded), expand it
   * - If the card has the props openDetails (dynamic card content details), open the details
   * - Then try to find the modelFieldElement again and if found, focus on it
   * Otherwise do nothing
   */
  scrollToModelField = async event => {
    const { onSetExpanded, openDetails } = this.props
    const { type, detail } = event
    const { modelFieldPath } = detail || {}
    let options
    const existingInnerPanelModelFieldElement = this.getInnerPanelModelFieldPathElement(modelFieldPath)
    let existingModelFieldElement = existingInnerPanelModelFieldElement || this.getModelFieldPathElement(modelFieldPath)
    if (!existingModelFieldElement && this.cardRef.current.getAttribute("data-model-field-path") === modelFieldPath) {
      existingModelFieldElement = this.cardRef.current
      options = { errorClass: "card-error-focus" }
    }

    if (existingModelFieldElement) {
      if (openDetails) openDetails()
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent(customEvents.navsCard.displayAllNavs))

        if (this.state.expanded) {
          this.focusOnElement(existingModelFieldElement, options)
        } else {
          this.toggle(true)
          setTimeout(() => this.focusOnElement(existingModelFieldElement, options), 370) // 370 is roughly the duration of the bootstrap height transition
        }
      })
      return
    }

    // This one is special because the persons are often presented in tabs.
    // Only one tab is rendered at a time (we could let them all render but this is costly)
    // so we must open and render the proper one before scroll to a field inside it.
    if (type === customEvents.card.scrollToModelField && modelFieldPath.startsWith("persons")) {
      window.dispatchEvent(new CustomEvent(customEvents.personComponent.openTab, { detail: { modelFieldPath } }))
      return
    }

    if (existingInnerPanelModelFieldElement) {
      existingInnerPanelModelFieldElement.dispatchEvent(new CustomEvent(customEvents.innerPanel.open))
    }

    if (this.getDocumentModelFieldPathElement(modelFieldPath)) return

    if (onSetExpanded) this.toggle(true)
    if (openDetails) openDetails()

    // timeout to let react render the details
    setTimeout(() => {
      const probablyExistingInnerPanelModelFieldElement = this.getInnerPanelModelFieldPathElement(modelFieldPath)
      const probablyExistingModelFieldElement = this.getModelFieldPathElement(modelFieldPath)
      if (probablyExistingInnerPanelModelFieldElement) {
        probablyExistingInnerPanelModelFieldElement.dispatchEvent(new CustomEvent(customEvents.innerPanel.open))
      }
      if (probablyExistingModelFieldElement) {
        this.focusOnElement(probablyExistingModelFieldElement)
      }
    })
  }

  toggle = forceExpand => {
    const { onSetExpanded } = this.props
    const expanded = forceExpand || !this.state.expanded
    this.setState({ expanded })
    onSetExpanded && onSetExpanded(expanded)
  }

  render() {
    const {
      debugInfo,
      legend,
      stats,
      title,
      titleColProps,
      titleSideText,
      titleLinkTo,
      category,
      children,
      ftTextCenter,
      hidden,
      textCenter,
      content,
      isHtmlContent,
      isMarkdown,
      onHtmlClick,
      collapse,
      action,
      className = "",
      disabled,
      noCard,
      dataAttributes,
      linkTo,
      download,
      modelFieldPath,
      entity,
    } = this.props

    let _modelFieldPath = modelFieldPath || debugInfo?.props?.modelFieldPath
    if (!_modelFieldPath && title) _modelFieldPath = labelToName(title)
    if (_modelFieldPath && debugInfo?.props && !debugInfo.props.modelFieldPath) debugInfo.props.modelFieldPath = _modelFieldPath
    const cardProps = entity ? getFieldProps(entity, _modelFieldPath) : {}

    const { expanded, showModalPopover } = this.state
    const { collapsible = 11, expandedWithAction = 6 } = titleColProps?.sm || {}

    /**
     * calculate isCollapsible here instead of constructor an keep in state.
     * A bug is detected when a script used in a page by using importscript, some cards contrucctor of this lib are not called
     */
    const isCollapsible = collapse !== undefined && collapse !== null
    const _dataAttributes = {}
    Object.keys(dataAttributes || {}).forEach(key => {
      if (key.startsWith("data-")) _dataAttributes[key] = dataAttributes[key]
    })

    const body = isHtmlContent ? (
      <div
        className={"content" + (isMarkdown ? " md-content" : "")}
        onClick={onHtmlClick}
        dangerouslySetInnerHTML={{
          __html: content, // markdown is sanitized in ContentComponent. Sanitizing htlm content can lead to remove customized html attribute (e.g onClickHandler)
        }}
      />
    ) : action ? (
      // We need to use a row here to keep the z-index order, notably when there are dropdowns in the card header.
      // However custom pages might have CSS not compatible with that wrapping, so we limit it to card having a header with actions.
      <Row className="content">
        <Col xs={12}>
          {content}
          {children}
        </Col>
      </Row>
    ) : (
      <div className="content">
        {content}
        {children}
      </div>
    )

    const cardClasses = applyClasses({
      card: true,
      [className]: true,
      "card-hidden": hidden,
      "card-html": isHtmlContent,
      "no-card": noCard,
      disabled,
    })

    const headerClasses = applyClasses({
      header: true,
      "text-center": textCenter,
      "c-pointer": isCollapsible,
    })

    let titleColSize = 12
    if (isCollapsible) titleColSize = collapsible
    if (expanded && action) titleColSize = expandedWithAction
    const titleCol = (
      // we need a min-height to avoid flicker when clicking on selectable rows in entities page
      <Col xs={isCollapsible ? 11 : 12} sm={titleColSize} className="title-col">
        <h4 className="title">
          <span
            tabIndex={isCollapsible ? "0" : "-1"}
            className={isCollapsible ? "outline-accessible" : ""}
            onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: () => isCollapsible && this.toggle() })}
          >
            {title}
          </span>

          {titleSideText?.type === "badge" ? (
            <CustomButton bsStyle={titleSideText?.style || "info"} bsSize="xs" className="ml-5px" fill round>
              {titleSideText.label}
            </CustomButton>
          ) : titleSideText ? (
            <span className="white-space-nowrap ml-5px">{titleSideText}</span>
          ) : (
            <></>
          )}

          {titleLinkTo && (
            <a
              href={window.location.origin + titleLinkTo}
              aria-label={`${loc("Link to")} ${window.location.origin + titleLinkTo}`}
              target="_blank"
              rel="noopener noreferrer"
              className="inline-flex"
            >
              <i className={`icn-external-link icn-xs text-link ${titleSideText ? "" : "ml-5px"}`} />
            </a>
          )}
        </h4>
      </Col>
    )

    const actionCol = (action || isCollapsible) && (
      <>
        {isCollapsible && (
          <Col xs={1} className={"h-30" + (action ? " card-collapse-col" : "")}>
            <div className={"float-right flex-center justify-content-end h-100" + (action ? " ml-5px" : "")}>
              <i className="section-collapse-icon icn-chevron-right icn-xs pull-right" data-is-expanded={expanded} />
            </div>
          </Col>
        )}
        {action && (
          <Col xs={12} sm={12 - titleColSize} className="h-30">
            {isCollapsible && (
              <div className={"card-collapse-action float-right flex-center justify-content-end h-100" + (action ? " ml-5px" : "")}>
                <i className="section-collapse-icon icn-chevron-right icn-xs pull-right" data-is-expanded={expanded} />
              </div>
            )}
            {expanded && <div onClick={e => e.stopPropagation()}>{action}</div>}
          </Col>
        )}
      </>
    )

    const popover = debug && debugInfo?.card && (
      <DebugPopover help={'Card "' + debugInfo.card + '"'} debug={debug} onClick={() => this.setState({ showModalPopover: true })} />
    )

    const message = cardProps?.errorFormat || cardProps?.warningFormat
    const card = (
      // warning : exceptionnally data-is-expanded is also used as an e2e test handle
      <div className={cardClasses} ref={this.cardRef} {..._dataAttributes} data-is-expanded={expanded} data-model-field-path={_modelFieldPath}>
        {popover}

        {(!!title || !!category || !!actionCol) && (
          <Row className={headerClasses} onClick={() => isCollapsible && this.toggle()}>
            {titleCol} {actionCol}
            {message ? (
              <Col xs={12}>
                <span className={cardProps?.errorFormat ? "card-error" : "card-warning"}>
                  {typeof message === "string" ? loc(message) : Array.isArray(message) ? loc(...message) : null}
                </span>
              </Col>
            ) : (
              ""
            )}
            {category && (
              <Col xs={12}>
                <p className="category">{category}</p>
              </Col>
            )}
          </Row>
        )}

        {isCollapsible && (
          <PanelOuter expanded={expanded} onToggle={this.toggle}>
            {body}
          </PanelOuter>
        )}

        {!isCollapsible && body}

        {stats !== undefined || legend !== undefined ? (
          <div className={"footer" + (ftTextCenter ? " text-center" : "")}>
            {legend !== undefined ? <div className="legend">{legend}</div> : null}
            {stats !== undefined ? <hr /> : null}
            {stats !== undefined ? <div className="stats">{stats}</div> : null}
          </div>
        ) : null}

        {showModalPopover && <EntityModelModal entity={debugInfo} close={() => this.setState({ showModalPopover: false })} />}
      </div>
    )

    if (linkTo) {
      if (download) {
        return (
          <a onClick={() => downloadFileFromUrl(linkTo, "Downloaded", "downloading")} className="text-color">
            {card}
          </a>
        )
      } else if (linkTo.startsWith("http")) {
        return (
          <a href={linkTo} className="text-color">
            {card}
          </a>
        )
      } else {
        return (
          <Link to={linkTo.startsWith("#") ? linkTo.substring(1) : linkTo} className="text-color">
            {card}
          </Link>
        )
      }
    }

    return card
  }
}

/**
 * Helper method to let the js framework know whether or to render again a card and prevent unecessary UI flickering.
 * The card index is not reliable because it might change.
 * WARNING : in layouts a card can be returned several times from the same object.
 * Therefore don't assign the key returned from this function to the card object otherwise the key of last instance of the card
 * will be set to all previous instances of it by mutation, leading to buggy rendering effects.
 * @param {[Object]} cards The array of cards in which belongs the card
 * @param {Object} card The card for which to determine a key
 * @param {Number} cardIndex The card index in the arrays of cards
 * @returns The card key
 */
function getCardKey(cards, card, cardIndex) {
  if (card.key) return card.key

  let nbOfSameCards = 0
  const cardName = card.card

  if (!cardName) {
    console.warn(`A card without a name has been passed at position ${cardIndex}`, card)
    return cardIndex
  }

  for (const _card of cards) {
    if (!_card) continue
    if (_card.card === cardName) nbOfSameCards++
    if (nbOfSameCards === 2) break
  }

  return nbOfSameCards > 1 ? cardName + cardIndex : cardName
}

export const navCssVarPrefix = "--nav-display-"
export const navCssVarAllSuffix = "all"

export function generatePageCards({ layout, getWorkflowCard, getCardComponent, useAppLoadingAnimation, modal = {}, showCollapseToggleBtn } = {}) {
  const cards = layout?.cards || []
  const hasCards = cards.length > 0
  if (!hasCards) {
    return useAppLoadingAnimation ? <AppLoadingAnimation /> : <LazySkeletonUi noPadding />
  }

  const firstCard = cards[0]
  const isFirstCardWorkflowSticky =
    firstCard?.card === cardNames.WORKFLOW && firstCard?.props?.hidden !== true && getConfigAtPath("styles.entityWorkflowCardPosition") === "sticky"

  const cardsColLeft = []
  const cardsColRight = []
  for (let i = 0; i < cards.length; i++) {
    const card = cards[i]
    if (!card || card.props?.hidden) continue
    if (isFirstCardWorkflowSticky && i === 0) continue

    const cardKey = getCardKey(cards, card, i)
    const cardMap = { card, cardKey }
    if (card.props?.colRight === true) {
      cardsColRight.push(cardMap)
    } else {
      cardsColLeft.push(cardMap)
    }
  }

  const hasRightCol = cardsColRight.length > 0
  const getCardsItems = cardsArray => {
    let navsComponentCount = 0
    return cardsArray.map(({ card, cardKey }) => {
      if (typeof getCardComponent === "function") {
        let _card = card
        if (card.card === cardNames.NAVS) {
          _card = { ...card }
          _card.props = card.props ? { ...card.props } : {}
          _card.props.isFirstNavsCard = navsComponentCount === 0
          navsComponentCount++
        }

        const cardComponent = card.card === cardNames.WORKFLOW ? getWorkflowCard(_card) : getCardComponent({ card: _card, layout })
        const { colProps, navs } = card.props || {}
        const { xs = 12, sm, md, lg, className = "" } = colProps || {}

        let style = null
        if (card.card !== cardNames.NAVS) {
          const _navs = Array.isArray(navs) ? navs : navs?.split(",")
          let displayStyle = ""
          for (let i = 0; i < _navs?.length; i++) {
            const nav = _navs[i].trim()
            displayStyle = i === 0 ? `var(${navCssVarPrefix}${nav}, none)` : `var(${navCssVarPrefix}${nav}, ${displayStyle})`
          }
          if (displayStyle) {
            displayStyle = `var(${navCssVarPrefix}${navCssVarAllSuffix}, ${displayStyle})`
            style = { display: displayStyle }
          }
        }

        return (
          <Suspense key={cardKey} fallback={null}>
            {React.isValidElement(cardComponent) ? (
              <Col {...{ xs, sm, md, lg }} style={style} className={className}>
                {cardComponent}
              </Col>
            ) : null}
          </Suspense>
        )
      }
    })
  }

  const sideColumnAttr = "data-show-side-column"

  return (
    <>
      {isFirstCardWorkflowSticky && typeof getWorkflowCard === "function" && getWorkflowCard(firstCard)}

      <Row data-show-side-column={getUiState(localStorageKeys.UI_STATE.CARD.SHOW_SIDE_COLUMN) || "true"}>
        <Col className="left-col" {...(layout?.colLeftColProps || (hasRightCol ? { xs: 12, md: 8 } : { xs: 12 }))}>
          <Row>{getCardsItems(cardsColLeft)}</Row>
        </Col>

        {/* This button must be next in the DOM to the cards collapse one so that tab key navigation goes from to the other sequentially */}
        {hasRightCol && (
          <div
            id={sideColumnToggleId}
            className="card-control-btn xs_d-none"
            data-offsets={hasRightCol ? 1 : 0}
            onClick={() => {
              const sideColumnElement = document.querySelector(`[${sideColumnAttr}]`)
              const sideColumnAttrValue = sideColumnElement.getAttribute(sideColumnAttr)
              const newRightColAttrValue = sideColumnAttrValue === "true" ? "false" : "true"
              sideColumnElement.setAttribute(sideColumnAttr, newRightColAttrValue)
              setUiState(localStorageKeys.UI_STATE.CARD.SHOW_SIDE_COLUMN, newRightColAttrValue)
            }}
          >
            <ButtonWithTooltip className="icn-chevron-right icn-sm text-white" btnClassName="m-0" tooltip={"Toggle side column (CTRL + B)"} />
          </div>
        )}

        {showCollapseToggleBtn && (
          <div
            id={cardsCollapseToggleId}
            className="card-control-btn"
            onClick={() => window.dispatchEvent(new CustomEvent(customEvents.card.toggle))}
          >
            <ButtonWithTooltip
              className="icn-arrows-to-line icn-sm text-white"
              btnClassName="m-0"
              tooltip={"Collapse or expand all cards (ALT + C)"}
            />
          </div>
        )}

        {hasRightCol && (
          <Col className="right-col" {...(layout?.colRightColProps || { xs: 12, md: 4 })}>
            <Row>{getCardsItems(cardsColRight)}</Row>
          </Col>
        )}
      </Row>

      {modal && (
        <Modal
          show={modal.show}
          onHide={modal.onHide}
          backdrop={modal.backdrop}
          data-page-name={modal.pageName}
          style={modal.style}
          bsSize={modal.size}
        >
          {modal.header && (
            <Modal.Header closeButton={modal.closeButton}>
              <Modal.Title>
                {typeof modal.header === "string"
                  ? loc(modal.header)
                  : modal.header?.cards
                  ? generatePageCards({ layout: modal.header, getCardComponent })
                  : null}
              </Modal.Title>
            </Modal.Header>
          )}

          {modal.body?.cards && <Modal.Body>{generatePageCards({ layout: modal.body, getCardComponent })}</Modal.Body>}

          {modal.footer?.cards && <Modal.Footer>{generatePageCards({ layout: modal.footer, getCardComponent })}</Modal.Footer>}
        </Modal>
      )}
    </>
  )
}

export default withRouter(Card)
