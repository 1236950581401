import get from "lodash.get"
import { getEntityClientRoute } from "./entityUtils.mjs"

function findYIndex(table = [], value) {
  const headers = table[0] || []

  let index
  if (value && isNaN(value)) {
    return headers.findIndex(header => header === value)
  } else {
    const number = value || 0
    index = headers.findIndex((value, index) => number <= value && number > headers[index - 1])
    if (index < 0) index = headers.length - 1 // If number > max header then take the last one
    if (!index) index = 1
  }
  return index
}

function findXIndex(table, value, value2) {
  const hasThreeDimensions = table[0] && !table[0][0] && !table[0][1]
  let index
  if (hasThreeDimensions && value && value2) {
    if (typeof value === "string" && typeof value2 === "string") index = table.findIndex(row => row && row[0] === value && row[1] === value2)
    else if (typeof value === "string") index = table.findIndex(row => row && row[0] === value && row[1] >= value2)
    else if (typeof value2 === "string") index = table.findIndex(row => row && row[0] >= value && row[1] === value2)
    else {
      index = table.findIndex(row => row && row[0] >= value && row[1] >= value2)
    }
  } else {
    if (typeof value === "string") index = table.findIndex(row => row && row[0] === value)
    else index = table.findIndex(row => row && row[0] >= value)
  }
  if (index === -1) return table.length - 1
  return index
}

// https://uat.hyperfront.io/documentation/scripts#table-utilities
function findTableValue(table, xValue, yValue, x2Value) {
  if (!table) return
  const x = findXIndex(table, xValue, x2Value)
  const y = findYIndex(table, yValue)
  return table?.[x]?.[y]
}

/**
 * Recursively traverses an object or array, and replaces non-object values
 * using the `replaceVariables` function. It dives deeper into objects or arrays
 * but leaves functions as they are.
 *
 * @param {Object|Array} obj - The object or array to traverse and process.
 * @param {Object} [data={}] - The data to be used in the `replaceVariables` function.
 * @param {Object} [options] - Additional options to control the replacement behavior.
 * @param {boolean} [options.useParsePath=false] - If true, string values enclosed in braces (e.g., "{some.path}") will be interpreted as paths and resolved using `parsePath`.
 * @returns {Object|Array} A new object or array with replaced values.
 *
 * @example
 * const result = deepReplaceVariables(
 *   { greeting: "{Hello}", user: { name: "{user.name}" } },
 *   { Hello: "Hi", user: { name: "Alice" } },
 *   { useParsePath: true }
 * );
 * console.log(result); // Output: { greeting: "Hi", user: { name: "Alice" } }
 */
function deepReplaceVariables(obj, data = {}, options) {
  if (Array.isArray(obj)) {
    return obj.map(item => deepReplaceVariables(item, data))
  } else if (obj !== null && typeof obj === "object") {
    const result = {}
    for (let key in obj) {
      if (obj[key]) {
        result[key] = deepReplaceVariables(obj[key], data)
      }
    }
    return result
  } else if (typeof obj === "function") {
    return obj // Leave function as is
  } else {
    if (options?.useParsePath && typeof obj === "string") {
      const isEnclosed = obj.startsWith("{") && obj.endsWith("}")
      const path = isEnclosed ? obj.substring(1, obj.length - 1) : obj
      const parsedPath = parsePath(data, path)
      return replaceVariables(isEnclosed ? `{${parsedPath}}` : parsedPath, data)
    } else {
      return replaceVariables(obj, data)
    }
  }
}

/**
 * @param {string} str
 * @param {object} data
 * @param {object} options
 * @param {boolean} options.getEntityNameClientRoute
 * @returns Returns the provided string with parts replaced by values found in the data object by matching keys with patterns :key and {key}. For instance providing a string "/contract/{registration}" and a data object { registration: "FINXXX" } would yield "/contract/FINXXX".
 */
function replaceVariables(str = "", data = {}, options) {
  const { getEntityNameClientRoute } = options || {}

  const entityNameHint = "{entityName}"
  if (getEntityNameClientRoute && str.includes(entityNameHint)) {
    str = str.replace(entityNameHint, getEntityClientRoute(get(data, "entityName")))
  }

  // /contract/{registration} => /contract/FINXXX
  if (str.includes("{")) str = str.replace(/{([^{}]*)}/g, (match, key) => get(data, key) ?? match)

  // /contract/:registration => /contract/FINXXX
  if (str.includes(":")) {
    const regex = new RegExp(":(" + Object.keys(data).join("|") + ")[/|?|&]", "g")
    str = (str + "/").replace(regex, (m, $1) => (data[$1] ? data[$1] + m[m.length - 1] : m))
    str = str.substring(0, str.length - 1)
  }

  return str
}

/**
 *
 * @param {[[*]]} array
 * @param {number} rowParam
 * @param {number} colParam
 * @returns Returns the item found at array[i][j] by comparing each cell with first rowParam and then colParam.
 */
function findTableEntry(array, rowParam, colParam) {
  let i
  let j
  for (i = 1; i < array.length - 1; i++) {
    if (array[i][0] <= rowParam && array[i + 1][0] > rowParam) break
  }
  const row0 = array[0]
  for (j = 1; j < row0.length - 1; j++) {
    if (row0[j] <= colParam && row0[j + 1] > colParam) break
  }
  if (i && j) return array[i][j]
}

/**
 * Converts a filtered object path into a standard path with resolved array indices.
 *
 * This function parses a dot/bracket notation path that may contain filter expressions
 * like `role=CLIENT` or `type=SIR`, and resolves them to the correct index in the array
 * by inspecting the given object.
 *
 * For example:
 * Input path: "persons[role=CLIENT].person.registrations[type=SIR].number"
 * Output path: "persons[1].person.registrations[0].number"
 *
 * @param {Object} obj - The object to resolve indices from.
 * @param {string} path - The string path to parse. May include filter expressions.
 * @returns {string} The parsed path with resolved indices, usable for direct access.
 */
function parsePath(obj, path) {
  if (!path.includes("=")) return path

  let parsedPath = ""

  // ["persons", "role=CLIENT", "person", "registrations", "type=SIR", "number"]
  const parts = path
    .split(".")
    .flatMap(part => (part.includes("[") ? part.split("[").flatMap(subPath => subPath.split("]")) : part))
    .filter(part => part)

  for (let [index, part] of parts.entries()) {
    // role=CLIENT
    if (part.includes("=") && index > 0) {
      const [key, value] = part.split("=") // ["role", "CLIENT"]
      const subEntities = get(obj, parsedPath) // entity.persons
      if (Array.isArray(subEntities)) {
        const subEntityIndex = subEntities.findIndex(e => e[key] === value)
        parsedPath += `[${subEntityIndex}]`
      }
    } else {
      parsedPath += index === 0 ? part : `.${part}`
    }
  }

  return parsedPath
}

export { deepReplaceVariables, findTableEntry, findTableValue, parsePath, replaceVariables }
