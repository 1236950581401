import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import CustomButton from "@/_components/CustomButton"
import FormContent from "@/_components/FormContent"
import FormInputExtended from "@/_components/FormInputExtended"
import LayoutCard from "@/_components/LayoutCard"
import PanelInner from "@/_components/PanelInner"
import Table from "@/_components/Table"
import { filterData, getSearchParamName } from "@/_services/boardUtils"
import { copyEntity } from "@/_services/copyPaste"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { defaultTablePageSize, searchParamToObject } from "@/_services/utils"
import axios from "axios"
import { cloneDeep } from "lodash"
import React from "react"
import { Col, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

class TableExtended extends React.Component {
  state = {
    page: 1,
    search: undefined,
    selectedRows: new Set(),
    rowsChildrenContent: new Map(),
    rowsLoading: new Set(),
  }

  componentDidMount() {
    const { location, collapseRows = true, data = [] } = this.props

    const { page } = searchParamToObject(location?.search)
    if (page !== undefined) this.setState({ page: Number(page) })
    if (!collapseRows && data.length) this.toggleAllRows()
  }

  componentDidUpdate(prevProps) {
    const { location, loading, collapseRows = true, data = [] } = this.props
    const { collapseRows: prevCollapseRows = true } = prevProps

    const { page } = searchParamToObject(location?.search)
    const { page: prevPage } = searchParamToObject(prevProps.location?.search)

    if (page !== undefined && page !== prevPage) {
      this.setState({ page: Number(page), selectedRows: new Set() })
    }
    if (data.length && ((prevProps.loading && !loading && !collapseRows) || prevCollapseRows !== collapseRows)) this.toggleAllRows()
  }

  toggleAllRows = () => {
    const { data = [] } = this.props
    for (const index in data) {
      const row = data[index]
      this.toggleRow(`${row._id || row.id || row.registration}|${index}`)
    }
  }

  toggleRow = async id => {
    const { selectedRows, rowsChildrenContent, rowsLoading } = this.state

    if (selectedRows.has(id)) selectedRows.delete(id)
    else {
      const rowIndex = Number(id.split("|")[1])
      let { data = [], loadRowChildren } = this.props

      if (loadRowChildren || (this.isUrl(data?.[rowIndex]?.children) && !rowsChildrenContent.has(id))) {
        // Row children can be defined as a url and fetched on demand
        rowsLoading.add(id)
        this.setState({ rowsLoading })

        try {
          if (loadRowChildren) {
            await loadRowChildren(data[rowIndex], rowIndex)
          } else {
            const content = (await axios.get(data[rowIndex].children)).data
            rowsChildrenContent.set(id, content)
          }
        } catch (error) {
          addOops(error)
        }

        rowsLoading.delete(id)
        this.setState({ rowsLoading })
      }

      selectedRows.add(id)
    }

    this.setState({ selectedRows })
  }

  handleSetPage = pageNumber => {
    const { changePage } = this.props
    if (changePage) changePage(pageNumber)
    this.setState({ page: pageNumber, selectedRows: new Set() })
  }

  handleSetSearch = search => {
    if (this.props.launchSearch) this.props.launchSearch(search)
    this.setState({ search, selectedRows: new Set() })
  }

  isUrl = str => typeof str === "string" && (str?.startsWith("/") || str?.startsWith("http"))

  // Determine if an action is enabled for a row. Indeed, some actions can be disabled for some rows only, using the `.action` field in the data.
  // The `.action` field can either be set as `false` or as a function returning a boolean to allow for greater flexibility.
  // The example below shows how to declare row-specific actions when the table is built based on `data: project.grantedFunds` :
  // Example : project.grantedFunds[0].action = { showDuplicate: false, showDelete: false }
  // Example : project.grantedFunds[0].action = { showDuplicate: project.grantedFunds.length < 5 }
  // Example : project.grantedFunds[0].action = { showDuplicate: ({row}) => row.amount !== 20000 }
  isActionEnabledForRow = (row, availableAction) => {
    // Check if availableAction global flag is a function
    if (typeof this.props[availableAction] === "function") return this.props[availableAction]({ row })
    // Check if availableAction global flag is set to `true` (it is considered disabled by default)
    if (this.props[availableAction] !== true) return false

    // Check if availableAction specific row flag is a function
    if (typeof row.action?.[availableAction] === "function") return row.action?.[availableAction]({ row })
    // Check if availableAction specific row flag is set to `false` (it is considered enabled by default)
    if (row.action?.[availableAction] === false) return false

    // Actions are enabled for all lines by default.
    return true
  }

  render() {
    let { search, selectedRows, page, rowsChildrenContent, rowsLoading } = this.state
    let {
      columns = [],
      data = [],
      rows = [],
      section,
      pageInUrl = false,
      pageSize = defaultTablePageSize,
      showAdd,
      showCopy,
      onRowDelete,
      onRowAdd,
      onRowDuplicate,
      ...props
    } = this.props

    if (props.searchFromUrl) {
      const queryParams = searchParamToObject(props.location.search)
      search = queryParams[getSearchParamName(props.searchFromUrl)]
    }

    data = filterData(columns, data, search)

    const additionalPageRows = Array.from(selectedRows)
      .map(r => Number(r.split("|")[1]))
      .filter(index => index < page * pageSize && index >= (page - 1) * pageSize).length

    // Columns whose formInputProps have inArray: false are displayed among the row children
    const notInArrayColumns = columns.filter(col => col?.formInputProps?.inArray === false)
    columns = columns.filter(col => !notInArrayColumns.includes(col))

    const panels = rows.filter(row => row.type === "panel")

    const hasAtLeastOneRowWithChildren = data.findIndex(d => d?.children) !== -1
    if (hasAtLeastOneRowWithChildren || panels.length + notInArrayColumns.length > 0) {
      const expandedCol = columns.find(c => c?.name === "expanded")
      if (!expandedCol) {
        // For accessibility checks, header cells must always have something in them
        columns.unshift({ title: "Expand or collapse", name: "expanded", className: "max-w-0 w-1 min-w-24px invisible" })
      }
    }

    const { entity, onSetState } = this.props
    // Available actions at the end of each row
    const availableActions = {
      showDelete: {
        method: "DELETE",
        pullRight: true,
        onClick: async args => {
          if (onRowDelete) {
            const patch = await onRowDelete(args)
            if (patch && onSetState) onSetState(patch)
            else if (onSetState) onSetState(entity) // for backward-compatibility
          } else {
            this.props.data.splice(args.row._index, 1)
            onSetState && onSetState(entity)
          }
        },
      },
      showDuplicate: {
        tooltip: "Duplicate",
        icon: "icn-duplicate icn-xs",
        pullRight: true,
        onClick: async args => {
          if (onRowDuplicate) {
            const patch = await onRowDuplicate(args)
            if (patch && onSetState) onSetState(patch)
            else if (onSetState) onSetState(entity) // for backward-compatibility
          } else {
            this.props.data.splice(args.row._index + 1, 0, cloneDeep(this.props.data[args.row._index]))
            onSetState && onSetState(entity)
          }
        },
      },
      showCopy: {
        tooltip: "Copy",
        icon: "icn-copy icn-xs",
        pullRight: true,
        onClick: args => {
          copyEntity(args.row)
          addNotification("Done")
        },
      },
    }

    const onRowChange =
      typeof props.onRowChange === "function"
        ? props.onRowChange
        : props.useDefaultOnRowChange === true || section
        ? ({ rowIndex, patch }) => {
            this.props.data[rowIndex] = { ...this.props.data[rowIndex], ...patch }
            if (entity) entity.isChanged = true
            return entity
          }
        : undefined

    data = data
      .map((row, rowIndex) => {
        // Add actions to the row, if enabled globally AND for the row
        const rowActions = []
        for (const availableAction of Object.keys(availableActions)) {
          if ((!this.props.readOnly || availableAction === "showCopy") && this.isActionEnabledForRow(row, availableAction)) {
            rowActions.push(availableActions[availableAction])
          }
        }
        if (rowActions.length) row.actions = rowActions

        if (row?.children || (row && panels.length) || (row && notInArrayColumns.length)) {
          const rowId = row._id || row.id || row.registration
          const id = `${rowId}|${rowIndex}`
          const loading = rowsLoading.has(id)

          const componentFromColumn = col => {
            const { modelPath, modelSectionPath } = this.props

            let _modelPath = col?.formInputProps?.modelPath || col?.modelPath
            // Compute modelPath if undefined (Ex: modelPath="persons[1].person" + modelSectionPath="employees" => "persons[1].person.employees[1]")
            if (!_modelPath && modelPath && modelSectionPath) _modelPath = [modelPath, `${modelSectionPath}[${rowIndex}]`].join(".")

            if (React.isValidElement(row[col.name])) return row[col.name]

            return (
              <FormInputExtended
                key={col.name}
                inArray={false}
                obj={row}
                field={col.name}
                label={col.title}
                {...col}
                {...col.formInputProps}
                modelPath={_modelPath}
                onSetState={patch => {
                  if (typeof props.onSetState !== "function") return
                  const rowPatch = onRowChange({ row: { ...row, _index: rowIndex }, patch, rowIndex })
                  if (rowPatch) props.onSetState(rowPatch)
                }}
              />
            )
          }

          const hasId = selectedRows.has(id)
          const rows = [
            {
              ...row,
              expanded: (
                <ButtonWithTooltip
                  simple
                  bsStyle="info"
                  bsSize="xs"
                  onClick={!loading && (() => this.toggleRow(id))}
                  tooltip={hasId ? "Collapse" : "Expand"}
                >
                  {loading && <i className="icn-circle-notch icn-spin icn-xs text-black-lightest" />}
                  {!loading && <i className={"icn-xs " + (hasId ? "icn-chevron-down" : "icn-chevron-right")} />}
                </ButtonWithTooltip>
              ),
              children: undefined,
            },
          ]

          if (hasId) {
            let childrenContent = <></>
            if (row.children) {
              if (typeof row.children === "string") {
                childrenContent = <LayoutCard noCard rows={[{ type: "content", ...rowsChildrenContent.get(id), noCard: false }]} />
              } else if (row.children.columns && row.children.data)
                childrenContent = <TableExtended {...props} searchFromUrl={false} {...row.children} />
              else if (row.children.fields) childrenContent = <FormContent {...row.children} />
              else childrenContent = row.children
            }

            if (row.children || notInArrayColumns.length)
              childrenContent = (
                <Row>
                  <Col xs={12}>{childrenContent}</Col>
                  {notInArrayColumns.map(componentFromColumn)}
                </Row>
              )

            const panelsContent = panels.map(panel => {
              return (
                <PanelInner key={panel.title} title={panel.title} collapse={true}>
                  {panel.rows.map((row, i) => {
                    return <Row key={`${panel.title}_row${i}`}>{row.map(componentFromColumn)}</Row>
                  })}
                </PanelInner>
              )
            })

            rows.push({
              className: "form-row",
              content: (
                <>
                  {childrenContent}
                  {panelsContent}
                </>
              ),
            })
          }
          return rows
        }
        return row
      })
      .flat()

    showAdd &&= !this.props.readOnly

    // Recreate a fresh actions column, else, pushing the add btn in the title will create a circular reference in the layout
    // and break conmparaisons throughout the client.
    let actionColumn = columns.find(col => col?.name === "actions")
    let actionColumnIndex = columns.indexOf(actionColumn)

    // Tot avoid mutations issues, remove the action column from the list, and rebuild it properly.
    if (actionColumn) columns = columns.filter(col => col !== actionColumn)

    // Rebuild actions columns and add it to the list of columns if needed.
    const title = actionColumn?.title || "actions"
    actionColumn = {
      title: typeof title === "string" ? loc(title) : title,
      name: actionColumn?.name || "actions",
      className: "w-5 text-right",
      sortable: false,
    }
    if (actionColumnIndex > -1 || (!this.props.readOnly && Object.keys(availableActions).some(action => this.props[action])) || showAdd || showCopy)
      columns.push(actionColumn)

    if (showAdd || showCopy)
      actionColumn.title = (
        <>
          {showAdd && (
            <CustomButton
              pullRight
              bsSize="xs"
              bsStyle="primary"
              className="inline-flex-center"
              simple
              onClick={async () => {
                if (onRowAdd) {
                  const rowPatch = await onRowAdd({ pageState: cloneDeep(entity) })
                  onSetState && onSetState(rowPatch || entity)
                } else {
                  this.props.data.push({})
                  onSetState && onSetState(entity)
                }
              }}
            >
              <i className="icn-plus icn-xs mr-5px" />
              {!showCopy && loc(`Add`)}
            </CustomButton>
          )}
          {showCopy && (
            <CustomButton
              pullRight
              bsSize="xs"
              bsStyle="info"
              className="inline-flex-center"
              simple
              onClick={() => {
                copyEntity(data)
                addNotification("Done")
              }}
            >
              <i className="icn-copy icn-xs" />
            </CustomButton>
          )}
        </>
      )

    return (
      <Table
        {...{ data, columns, pageInUrl, pageSize, additionalPageRows }}
        filterData={(cols, data) => data}
        handleSetSearch={this.handleSetSearch}
        handleSetPage={this.handleSetPage}
        {...props}
        onRowChange={onRowChange}
        className={`table-extended ${props.className || ""}`}
      />
    )
  }
}

export default withRouter(TableExtended)
